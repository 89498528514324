import moment from "moment";
import axios from "axios";

export async function getToken() {
    const token = localStorage.getItem('token');

    await window.axios.get(route('telegram-login.user'), {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).catch(async (err) => {
        if(err.response.data.message == 'Unauthenticated.') {
            await generateNewToken();
        } else {
            alert('Something went wrong with the authentication. Please refresh the page.')
        }
    });

    return getTokenFromStorage();
}

export async function generateNewToken() {
    await window.axios.post(route('telegram-login.get-token'), {
        withCredentials: true
    }).then((res) => {
        localStorage.setItem('token', res.data.token);
        //console.log(res.data.token)
    }).catch((err) => {
        alert('There was an error authenticating you. Please refresh the page.')
    });

    return getTokenFromStorage();
}

export function getTokenFromStorage(): string | null {
    return localStorage.getItem('token');
}

export function getTelegramData(data: string) {
    return window.axios.post(route('telegram.verify-init-data'), {
        data: data
    })
}
