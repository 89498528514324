/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
import {generateNewToken, getToken} from "@/Classes/AuthService";

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.interceptors.request.use(
    config => {
        // Get the token from localStorage
        const token = localStorage.getItem('token');

        // If the token exists, add it to the Authorization header
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

window.axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
    },
    async function (error) {
        const { response } = error;
        if (response && response.status === 401 && response.data.message === "unauthenticated") {
            // Add your token refresh logic here
            try {
                // Example: Call a function to refresh the token
                await generateNewToken();

                const token = localStorage.getItem('token');
                error.config.originalRequest.headers['Authorization'] = `Bearer ${token}`;

                // Retry the original request
                return window.axios(error.config);
            } catch (refreshError) {
                // Handle the case where token refresh fails
                return Promise.reject(refreshError);
            }
        }

        // Return any other error
        return Promise.reject(error);
    }
);

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT,
    wssPort: import.meta.env.VITE_REVERB_PORT,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    unavailableTimeout: 2000,
    enabledTransports: ['ws', 'wss'],
    logToConsole: true,
});
