import './bootstrap';
import '../css/app.css';

import { createApp, h, DefineComponent } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { VueTelegramPlugin } from 'vue-tg'
import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        return resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue'))
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
                Sentry.replayCanvasIntegration(),
                /*Sentry.captureConsoleIntegration({
                    levels: ['error'],
                })*/
            ],

            environment: import.meta.env.VITE_APP_ENV,
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for tracing.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.1,

            // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
            tracePropagationTargets: ["https://tongame.test", /^https:\/\/app\.swipearena\.com/],

            trackComponents: true,
            logErrors: true,
            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

        app.config.errorHandler = (err, vm, info) => {
            Sentry.captureException(err);
        };

        app.use(plugin)
            .use(ZiggyVue)
            .use(VueTelegramPlugin)
            .mount(el);

        return app;
    },
    progress: {
        color: '#4B5563',
    },
});
